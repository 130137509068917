$(document).ready(function () {
  $(".location i").addClass("brinca-brinca");
  $("body").css("display", "block");
  $("body").addClass("fadeIn");
  // detect navbar position
  var domain = "https://www.casamariachiparker.com/";
  var home_ = domain;
  var about_ = domain + "about-us";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  const pages = [
    { page: "home", file: home_ },
    { page: "about-us", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];

  $(
    ".home, .about-us, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];
      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });
      if (pageObj) {
        var file = pageObj.file;
        window.location.href = file;
      }
    });
  });

  //Add nav activo
  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;
    $(".page").each(function () {
      var link = $(this).text().toLowerCase();
      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });
    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });

  // Add class on scroll to button up
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 50) {
      $("header").addClass("sticky_header");
      $(".up").addClass("show_btn");
      $(".opn-cls span").addClass("achica");
    } else {
      $("header").removeClass("sticky_header");
      $(".up").removeClass("show_btn");
      $(".opn-cls span").removeClass("achica");
    }
  });

  // Button Up
  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
  });

  // Cuando se hace clic en '.opn-cls-orders'
  $(".opn-cls-orders").on("click", function (event) {
    // Evitar que el clic se propague al documento
    event.stopPropagation();
    // Alternar la clase 'expande' en '.nav-box'
    $(".nav-box").toggleClass("expande");
    $(".opn-cls-orders i").toggleClass("rotate-it");
  });

  // Cuando se hace clic en cualquier lugar del documento
  $(document).on("click", function (event) {
    // Verificar si el clic no ocurrió dentro de '.nav-box' o '.opn-cls-orders'
    if (
      !$(event.target).closest(".nav-box").length &&
      !$(event.target).closest(".opn-cls-orders").length
    ) {
      // Si no ocurrió dentro de ninguno de los elementos, cerrar la '.nav-box'
      $(".nav-box").removeClass("expande");
      $(".opn-cls-orders i").removeClass("rotate-it");
    }
  });

  //Funcion para manipular el navbar en mobile - pantallas chicas
  $(".opn-cls").on("click", function () {
    $("nav ul").toggleClass("open--cls");
    $(".opn-cls span").toggleClass("equis");
  });

  //Funcion para manipular si se da click por fuera del elemento
  $(document).on("click", function (event) {
    // Verificar si el clic fue fuera del menú y si el menú está abierto
    if (
      !$(event.target).closest("nav").length &&
      $("nav ul").hasClass("open--cls")
    ) {
      $("nav ul").removeClass("open--cls");
      $(".opn-cls span").removeClass("equis");
    }
  });

  //Quitar data en url para slider galeria
  var allowedPaths = ["/home", "/about-us", "/", "/catering"];
  var currentPath = window.location.pathname.replace(".html", "");
  //console.log('Ubicación: ' + currentPath);
  if (allowedPaths.includes(currentPath)) {
    $('[data-fancybox="gallery"]').fancybox({
      hash: false,
    });

    //Function to control the Slider
    $(document).ready(function () {
      var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

      var cycle = function (index) {
        var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

        nextIndex = index !== undefined ? index : nextIndex;

        $currentSlide = $($slides.get(currentIndex));
        $currentSelector = $($selectors.get(currentIndex));

        $nextSlide = $($slides.get(nextIndex));
        $nextSelector = $($selectors.get(nextIndex));

        $currentSlide.removeClass("active").css("z-index", "0");

        $nextSlide.addClass("active").css("z-index", "1");

        $currentSelector.removeClass("current");
        $nextSelector.addClass("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < $slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
      };

      $(function () {
        currentIndex = 0;
        nextIndex = 1;

        $slides = $(".slide");
        $selectors = $(".selector");
        $btns = $(".btn");

        $slides.first().addClass("active");
        $selectors.first().addClass("current");

        interval = window.setInterval(cycle, 4000);

        $selectors.on("click", function (e) {
          var target = $selectors.index(e.target);
          if (target !== currentIndex) {
            window.clearInterval(interval);
            cycle(target);
            interval = window.setInterval(cycle, 4000);
          }
        });

        $btns.on("click", function (e) {
          window.clearInterval(interval);
          if ($(e.target).hasClass("prev")) {
            var target =
              currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
            cycle(target);
          } else if ($(e.target).hasClass("next")) {
            cycle();
          }
          interval = window.setInterval(cycle, 4000);
        });
      });

      var menu = [];
      $(".swiper-slide").each(function (index) {
        menu.push($(this).find(".slide-inner").attr("data-text"));
      });

      var interleaveOffset = 0.5;
      var swiperOptions = {
        loop: true,
        speed: 1000,
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 0,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      var swiper = new Swiper(".swiper-container", swiperOptions);

      var sliderBgSetting = $(".slide-bg-image");
      sliderBgSetting.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css(
            "background-image",
            "url(" + $(this).data("background") + ")"
          );
        }
      });
    });
  }

  // Navbar on menu
  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");
    menuDivs.hide();
    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");
    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();
        menuDivs.eq(index).show();
        tabs.removeClass("colorea");
        $(this).addClass("colorea");
      });
    });
  }
  menuTabs();

  // Data scroll to in home page
  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  console.log("Ubicación: " + currentPath);
  if (allowedPaths.includes(currentPath)) {
    //Data scroll to que mande al article.vips
    $(document).ready(function () {
      // Función para hacer scroll hacia el elemento con la clase .vips
      function scrollToVips() {
        var vipsElement = $(".vips");
        if (vipsElement.length > 0) {
          $("html, body").animate(
            {
              scrollTop: vipsElement.offset().top,
            },
            1000
          ); // Puedes ajustar la duración de la animación según tus preferencias
        }
      }
      // Asociar la función al evento de clic en elementos con el atributo data-scroll-to
      $("[data-scroll-to]").on("click", function () {
        scrollToVips();
      });
    });
  }

  // Data scroll to en la página de catering
  var allowedPaths = ["/catering"];
  var currentPath = window.location.pathname.replace(".html", "");
  console.log("Ubicación: " + currentPath);
  if (allowedPaths.includes(currentPath)) {
    // Función para hacer scroll hacia el elemento con la clase .large
    function scrollToLarge() {
      var largeElement = $(".large");
      if (largeElement.length > 0) {
        $("html, body").animate(
          {
            scrollTop: largeElement.offset().top,
          },
          1000
        ); // Puedes ajustar la duración de la animación según tus preferencias
      }
    }

    // Asociar la función al evento de clic en elementos con el atributo data-scroll-to
    $(document).ready(function () {
      $("[data-scroll-to]").on("click", function () {
        scrollToLarge();
      });
    });
  }

  // Show modal on home page after 3 seconds
  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  // Esperar 2 segundos después de que la página se haya cargado
  setTimeout(function () {
    if (allowedPaths.includes(currentPath)) {
      // Agregar la propiedad visibility: visible al body
      $("body").addClass("no-scroll");
      $(".modal").addClass("show_modal");
      $(".show_modal").addClass("fadeIn");
      // Cerrar el modal
      $(".btn_modal").on("click", function () {
        $(".modal").addClass("animationless");
        $(".wrap").addClass("animationless2");
        $(".show_modal").removeClass("fadeIn");
        $("body").removeClass("no-scroll");
      });
    }
  }, 2000); // 2000 milisegundos (2 segundos)

  //Get Full Year
  $(".year").each(function () {
    var currentDate = new Date();
    var ANHO = currentDate.getFullYear();
    $(this).text(ANHO);
  });

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <source> dentro de los elementos <video>
    $("video source").each(function () {
      // Obtén la URL actual del archivo de video
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });

    // Recarga el video para aplicar el nuevo src
    $("video").each(function () {
      this.load();
    });
  });

  //Location Name
  LOCATION_NAME = "casa mariachi";
  //Description
  ABOUT_HOME =
    "Casa Mariachi is a family and locally owned and operated restaurant serving authentic and homemade Mexican food. We offer dine-in, take-out, delivery, online ordering, and catering. We treat our guests like family and know them by name. You'll always receive friendly and great service from our staff. <br><br> Our restaurant features outdoor seating and...";
  ABOUT_ABOUT =
    "Casa Mariachi is a family and locally owned and operated restaurant serving authentic and homemade Mexican food. We offer dine-in, take-out, delivery, online ordering, and catering. We treat our guests like family and know them by name. You'll always receive friendly and great service from our staff. <br><br> Our restaurant features outdoor seating and is wheelchair accessible. We have 15 years of experience preparing authentic and homemade Mexican food. Stop by for lunch or dinner or call <em class='phone'></em> to place your order.";
  //Address
  ADDRESS = "9771 S Parker Rd, Parker";
  ADDRESS_URL = "https://maps.app.goo.gl/eRmY9CQs9h9ETHn96";
  //Email
  EMAIL = "potrigol9@gmail.com";
  //Phone
  PHONE_NUMBER = "(720) 842-5105";
  //Hours & Days
  D1 = "sun - thu";
  D2 = "fri - sat";
  H1 = "11:00 a.m. - 9:00 p.m.";
  H2 = "11:00 a.m. - 9:30 a.m.";
  GOOGLE_DESK =
    "https://www.google.com/search?channel=fs&client=ubuntu-sn&q=casa+mariachi+parker#lrd=0x876c8e13fe5c767b:0xfe5f89f0e4c153e9,3,,,,";
  GOOGLE_MOB =
    "https://www.google.com/search?q=casa+mariachi+reviews&client=ms-android-attmexico-mx-revc&sca_esv=562865996&sxsrf=AB5stBhPZbqN05-kGmuYcUBG6Gko-IYPwQ%3A1693954725269&ei=pbL3ZMeGEIrGkPIPnouc4Ao&oq=casa+mariachi+parker+rd&gs_lp=EhNtb2JpbGUtZ3dzLXdpei1zZXJwIhdjYXNhIG1hcmlhY2hpIHBhcmtlciByZDIFECEYoAEyBRAhGKABSOMcUJYWWMIZcAF4AZABAJgB9gGgAbEFqgEDMi0zuAEDyAEA-AEBwgIKEAAYRxjWBBiwA8ICEBAuGIoFGMcBGK8BGLADGEPCAgoQABiKBRiwAxhDwgIHEAAYExiABMICCBAAGBYYHhgTwgIIECEYFhgeGB3iAwQYACBBiAYBkAYF&sclient=mobile-gws-wiz-serp&si=ACFMAn-CcYlA4IboL-ku9xtahy2HBUO66LVAKqlld0CKs0ZzGgzuXwgM-TJDm_p7sWkHWvYdTO9_VcyuzK-0M1VlmGXXeStTWrgeEDtUIcEltffU3efbngirW1oNBCe33KoT7-4-k44p_6PYItx-xZAcyOt9kZdPWg%3D%3D&ictx=1&ved=2ahUKEwj51KWWyZSBAxU4K0QIHetvAuQQyNoBKAJ6BAgyEAk";
  //Orders Online
  DELIVERY1 = "https://casamariachi.smartonlineorder.com/";
  //Iframe
  IFRAME =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12307.623219767447!2d-104.7697814!3d39.5391789!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c8e13fe5c767b%3A0xfe5f89f0e4c153e9!2sCasa%20Mariachi!5e0!3m2!1ses-419!2smx!4v1713555547545!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  DESC01 = "order your favorite dishes in seconds!";
  DESC02 = "subscribe to <br> our vip club!";
  //Social Media
  FACEBOOK = "https://www.facebook.com/CasaMariachiParkerCO";
  INSTAGRAM = "https://www.instagram.com/casamariachi_";
  YELP = "https://www.yelp.com/biz/casa-mariachi-parker";
  //Logo
  $("header img").css("cursor", "pointer");
  $("header img").on("click", function () {
    window.location.href = domain;
  });
  //Location - Name
  $(".location_name").each(function () {
    $(this).text(LOCATION_NAME);
  });
  //Location - Address
  $(".address").each(function () {
    $(this).text(ADDRESS);
  });
  $(".address-link").each(function () {
    $(this).click(function () {
      window.open(ADDRESS_URL, "_blank");
    });
  });
  //About Us
  $(".description_home").each(function () {
    $(this).html(ABOUT_HOME);
  });
  $(".description_about").each(function () {
    $(this).html(ABOUT_ABOUT);
  });
  //Phone
  $(".phone").each(function () {
    $(this)
      .text(PHONE_NUMBER)
      .click(function () {
        window.location.href = "tel:" + PHONE_NUMBER;
      });
  });
  // Email
  $(".email").each(function () {
    $(this)
      .text(EMAIL)
      .click(function () {
        window.location.href = "mailto:" + EMAIL;
      });
  });
  //Hours
  $(".d1").each(function () {
    $(this).text(D1);
  });
  $(".h1").each(function () {
    $(this).text(H1);
  });
  $(".d2").each(function () {
    $(this).text(D2);
  });
  $(".h2").each(function () {
    $(this).text(H2);
  });
  //Iframe
  $(".iframe").each(function () {
    $(this).html(IFRAME);
  });
  //Description Modal
  $(".description-01").each(function () {
    $(this).html(DESC01);
  });
  $(".description-02").each(function () {
    $(this).html(DESC02);
  });
  //Google Reviews
  $(".desk-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_DESK, "_blank");
    });
  });
  $(".mob-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_MOB, "_blank");
    });
  });
  //3rd Party Vendors
  $(".order-delivery1").each(function () {
    $(this).click(function () {
      window.open(DELIVERY1, "_blank");
    });
  });
  //Social Media
  $(".fb").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".ig").each(function () {
    $(this).click(function () {
      window.open(INSTAGRAM, "_blank");
    });
  });
  $(".yp").each(function () {
    $(this).click(function () {
      window.open(YELP, "_blank");
    });
  });
});
